
import React, {useState} from "react"

import Layout from "../../layout"
import WebappsForm from "../../webapps/form"

const negosyonowAPI = require("../../../../lib/requestNegosyownow")
const webappsAPI = require("../../../../lib/requestWebapps");


const ENTITYID="nntagexclusive"

const searchFields = [
	{"label":"Start", "dbfield": "nntagexclusive_startdatetime", "type": "datetime", "filtertype": "datetime"},
	{"label":"End", "dbfield": "nntagexclusive_enddatetime", "type": "datetime", "filtertype": "datetime"},
	{"label":"Name", "dbfield": "nntagexclusive_name", "type": "text", "filtertype": "text"},
	{"label":"Description", "dbfield": "nntagexclusive_description", "type": "text", "filtertype": "text"},
];


const formFields = [
	[
		{"label":"Name", "field": "nntagexclusive_name", "value": "", "input": "textbox", "mode": "readonly"},
		{"label":"Description", "field": "nntagexclusive_description", "value": "", "input": "textarea", "mode": "readonly"},
		{"label":"Start Date", "field": "nntagexclusive_startdatetime", "value": "", "input": "datetime", "mode": "required"},
		{"label":"End Date", "field": "nntagexclusive_enddatetime", "value": "", "input": "datetime", "mode": "required"},
	]
];



const TagexclusiveItemsForm = ({location, customValidateValues, customSubmit, editSubFormFields, customEditFieldInfo}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");

	function checkCustomSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(response){
			if (response.status === "OK") {
				updateEntityUsers(response.documentid, "franchise", function(franchiseres) {
					updateEntityUsers(response.documentid, "seminar", function(seminarres) {
						if (typeof customSubmit !== "undefined") {
							customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback);
							return;
						}
						callback(response);
					});
				});
			} else {
				callback(response);
			}
		});

		//callback({"status":"Error", "message":"Debug"}); return;
	}

	function updateEntityUsers(nntagexclusive_id, target, callback)
	{
		const targetpkidfield = "nn"+target+"_id";
		// Generate user list of selected franchise/seminar
		webappsAPI.loadData("nn"+target+"exclusive", {
			"dbfieldlist":[targetpkidfield],
			"filters":[[{
				field: "nntagexclusive_id",
				operation: "=",
				value: nntagexclusive_id
			}]]
		}, token).then(dataresponse => {
			if (dataresponse.status === "OK") {
				var idlist = [];
				var idx = 0;
				while (idx < dataresponse.data.length) {
					idlist.push(dataresponse.data[idx][targetpkidfield]);
					idx++;
				}
				if (idlist.length > 0) {
					negosyonowAPI.buildEntityIdListbyParam({"idlist":idlist, "mode": "content"},token, target).then(nnresponse => {
						callback(nnresponse);
					});
					return;
				}
			}
			callback(dataresponse);
		});
	}

	function checkCustomValidateValues(mainform, subformlist, footerformlist)
	{
		if (typeof customValidateValues !== "undefined") {
			return customValidateValues(mainform, subformlist, footerformlist);
		}
		return {"status":"OK"};
	}

	function checkCustomEditFieldInfo(inputlist, idx)
	{
		if (typeof customEditFieldInfo !== "undefined") {
			return customEditFieldInfo(inputlist, idx);
		}
		return inputlist[idx];

	}

	return <Layout location={location}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}
		>
			<WebappsForm
				location={location}
				pagetitle={pagetitle}
				allowadd={false}
				allowdelete={false}
				entity={ENTITYID}
				mobilerowtitlefield={["nntagexclusive_name"]}
				userSearchParam={{"orderby":"nntagexclusive_startdatetime desc"}}
				searchFields={searchFields}
				editFields={formFields}
				editSubFormFields={editSubFormFields}
				customSubmit={checkCustomSubmit}
				customValidateValues={checkCustomValidateValues}
				customEditFieldInfo={checkCustomEditFieldInfo}
				token={token} />
		</Layout>
}


export default TagexclusiveItemsForm;
